/* eslint-disable no-unused-vars */
import {
  HOMEPAGE_CMS_DATA,
  HOMEPAGE_GET_DATA,
  HOMEPAGE_NEWS_DATA,
  OTEHR_WEIR_APPS_CMS_DATA,
  OTHER_WEIR_APPS,
} from './HomePageActionTypes';
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { loaderActions, notificationActions } from '../../shared/_actions';
import { serviceFactory } from '../../services/_helpers/serviceFactory';
import { notificationConstants } from '../../shared/_constants';
import { networkStatus } from '../../shared/Helpers/networkStatus';
import languageMsg from './languageMsg';

export const homePage_Data = (data) => ({
  type: HOMEPAGE_GET_DATA,
  payload: data,
});

export const fetchHomePageCMSData = (data) => ({
  type: HOMEPAGE_CMS_DATA,
  payload: data,
});

export const fetchLatestNewsCMSData = (data) => ({
  type: HOMEPAGE_NEWS_DATA,
  payload: data,
});

export const otherWeirApps = (apps) => ({
  type: OTHER_WEIR_APPS,
  payload: apps,
});

export const otherWeirAppsCMSData = (apps) => ({
  type: OTEHR_WEIR_APPS_CMS_DATA,
  payload: apps,
});

export function fetchHomePageData(token) {
  const apiUrl = `${
    process.env.REACT_APP_CDE_BASEURLV2 + SERVICE_CONSTANTS.getApplicationData
  }`;
  return async (dispatch) => {
    dispatch(loaderActions.show());
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: SERVICE_CONSTANTS.headersAcceptv2,
        },
      });

      const user = await response.json();
      dispatch(loaderActions.hide());
      const applications = user.applications || [];
      dispatch(homePage_Data(applications));
    } catch (error) {
      dispatch(loaderActions.hide());
      serviceFactory.apiErrorHandle(error, dispatch);
      console.log('Fetch Value:', error);
    }
  };
}

export const fetchCMSHomePageData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.homePage;
    const homePageApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`;
    axios
      .get(homePageApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(fetchHomePageCMSData(response.data));
      })
      .catch((err) => {
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};

export const fetchCMSLatestNewData = (token) => {
  return (dispatch) => {
    dispatch(loaderActions.show());
    const appendUrl = SERVICE_CONSTANTS.homePageNews;
    axios
      .get(
        `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`,
        {
          mode: 'no-cors',
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept-Language': 'en',
          },
        }
      )
      .then((response) => {
        dispatch(loaderActions.hide());
        dispatch(fetchLatestNewsCMSData(response.data));
      })
      .catch((err) => {
        dispatch(loaderActions.hide());
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};

export const fetchOtherWeirAppsData = (token) => {
  return (dispatch) => {
    const appendUrl = SERVICE_CONSTANTS.moreApps;
    axios
      .get(`${process.env.REACT_APP_CDE_BASEURLV2}${appendUrl}`, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: SERVICE_CONSTANTS.headersAcceptv2,
        },
      })
      .then((response) => {
        dispatch(loaderActions.hide());
        dispatch(otherWeirApps(response.data));
      })
      .catch((err) => {
        dispatch(loaderActions.hide());
        console.error('Error in more apps:', err);
      });
  };
};

export const fetchCMSMoreAppsData = (token) => {
  return (dispatch) => {
    dispatch(loaderActions.show());
    const appendUrl = SERVICE_CONSTANTS.moreAppsCmsContent;
    axios
      .get(
        `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL_PREP}${appendUrl}`,
        {
          mode: 'no-cors',
          headers: {
            Authorization: `Bearer ${token}`,
            'Accept-Language': 'en',
          },
        }
      )
      .then((response) => {
        dispatch(loaderActions.hide());
        dispatch(otherWeirAppsCMSData(response.data));
        console.log('other weir CMS Data:', response.data);
      })
      .catch((err) => {
        dispatch(loaderActions.hide());
        console.error('Error in more apps cms data:', err);
      });
  };
};

export const submitRequestMoreApps =
  (iamsToken, selectedMoreAppsData) => (dispatch) => {
    // debugger;
    const token = iamsToken;
    dispatch(loaderActions.show());
    const appendUrl = SERVICE_CONSTANTS.moreAppsSubmit;

    const reqBody = {
      appObjectIds: selectedMoreAppsData,
    };
    axios
      .post(
        `${process.env.REACT_APP_CDE_API_BASEURL_ACCESS}${appendUrl}`,
        reqBody,
        {
          mode: 'no-cors',
          headers: {
            Authorization: `Bearer ${iamsToken}`,
            Accept: SERVICE_CONSTANTS.headersAcceptv1,
          },
        }
      )
      .then(() => {
        dispatch(loaderActions.hide());
        dispatch(fetchOtherWeirAppsData(token));
        dispatch(
          notificationActions.showNotification({
            notificationType: languageMsg.MESSAGE,
            notificationTitle: languageMsg.SUBMIT_NOTIFICATION_TITLE,
            notificationMessage: languageMsg.SUBMIT_NOTIFICATION_MESSAGE,
          })
        );
      })
      .catch((err) => {
        dispatch(loaderActions.hide());
        const titleText =
          err?.response?.status === 404
            ? networkStatus[404]
            : err?.response?.data?.message || networkStatus[901];
        dispatch(
          notificationActions.showNotification({
            notificationType: notificationConstants.ERROR,
            notificationTitle: 'Error!',
            notificationMessage: titleText,
            onClose: () => {},
          })
        );
      });
  };
