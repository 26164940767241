/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './main.module.css';
import { PortalFooter } from '@weirgroup/weir-dls';
import { fetchCMSHomePageData } from '../../modules/Home/HomePageAction';
import { useMsal } from '@azure/msal-react';
import { getSilentToken } from '../../services/TokenUtils';
import { msalConstants } from '../../services/msal.constants';

export default function FooterComponent() {
  const dispatch = useDispatch();
  const { accounts, instance } = useMsal();
  const { homePageCMSData } = useSelector((state) => state.homePageReducer);
  useEffect(() => {
    const getTokenAndFetchData = async () => {
      try {
        const token = await getSilentToken(accounts[0], instance, [
          msalConstants.apiApplicationConfig.scopes[0],
        ]);

        if (token) {
          dispatch(fetchCMSHomePageData(token));
        } else {
          console.log('Token not retrieved');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    // Call the function
    getTokenAndFetchData();
  }, [accounts, instance]);

  return (
    <div>
      <div className={styles.footer}>
        <PortalFooter
          CopyrightText={
            homePageCMSData[0]?.navigation?.navigationSections[1]?.i18n
              .copyright || ''
          }
          NavigationLinks={[
            {
              name:
                homePageCMSData[0]?.navigation?.navigationSections[1]?.items[2]
                  ?.name || '',
              url: '/site-information/terms-of-conditions',
            },
            {
              name:
                homePageCMSData[0]?.navigation?.navigationSections[1]?.items[1]
                  ?.name || '',
              url: '/site-information/privacy-statement',
            },
            {
              name:
                homePageCMSData[0]?.navigation?.navigationSections[1]?.items[0]
                  ?.name || '',
              url: '/site-information/cookies-policy',
              // homePageCMSData[0]?.navigation?.navigationSections[1]?.items[0]
              //   ?.url || '',
            },
            {
              name: 'Modern slavery statement',
              url: 'https://www.google.com/',
            },
          ]}
        />
      </div>
    </div>
  );
}
