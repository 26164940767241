import {
  APPLICATIONSPAGE_CMS_DATA,
  APPLICATIONSPAGE_GET_DATA,
} from './ApplicationsPageActionTypes';
import SERVICE_CONSTANTS from '../../shared/_constants/services.constants';
import axios from 'axios';
import { loaderActions } from '../../shared/_actions';
import { serviceFactory } from '../../services/_helpers/serviceFactory';

export const applicationsPage_Data = (data) => ({
  type: APPLICATIONSPAGE_GET_DATA,
  payload: data,
});

export const fetchApplicationsPageCMSData = (data) => ({
  type: APPLICATIONSPAGE_CMS_DATA,
  payload: data,
});

export function fetchApplicationsPageData(token) {
  const apiUrl = `${
    process.env.REACT_APP_CDE_BASEURLV2 + SERVICE_CONSTANTS.getApplicationData
  }`;
  return async (dispatch) => {
    dispatch(loaderActions.show());
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: SERVICE_CONSTANTS.headersAcceptv2,
        },
      });

      const user = await response.json();
      dispatch(loaderActions.hide());
      const applications = user.applications || [];
      dispatch(applicationsPage_Data(applications));
    } catch (error) {
      dispatch(loaderActions.hide());
      serviceFactory.apiErrorHandle(error, dispatch);
      console.log('Fetch Value:', error);
    }
  };
}

export const fetchCMSApplicationsPageData = (token) => {
  return (dispatch) => {
    dispatch(loaderActions.show());
    const appendUrl = SERVICE_CONSTANTS.applicationsPage;
    const applicationsPageApi = `${process.env.REACT_APP_CDE_PORTALWEIR_API_BASEURL}${appendUrl}`;
    axios
      .get(applicationsPageApi, {
        mode: 'no-cors',
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept-Language': 'en',
        },
      })
      .then((response) => {
        dispatch(loaderActions.hide());
        dispatch(fetchApplicationsPageCMSData(response.data));
      })
      .catch((err) => {
        dispatch(loaderActions.hide());
        serviceFactory.apiErrorHandle(err, dispatch);
        console.error('Error in cms api:', err);
      });
  };
};
