/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styles from './Applications.module.css';
import {
  CtaIcon,
  ButtonGroup,
  IconButton,
  ChipFilter,
} from '@weirgroup/weir-dls';
import { msalConstants } from '../../services/msal.constants';
import { getSilentToken } from '../../services/TokenUtils';
import { useMsal } from '@azure/msal-react';
import {
  fetchCMSApplicationsPageData,
  fetchApplicationsPageData,
} from './ApplicationsPageAction';
import { useDispatch, useSelector } from 'react-redux';

function Applications() {
  const [style, setStyle] = useState('Grid');
  const [selectedChip, setSelectedChip] = useState('All');

  const chipsData = [
    {
      label: 'All',
      chipIcon: '',
      selected: selectedChip === 'All',
      onClick: () => handleChipClick('All'),
    },
    {
      label: 'Web',
      chipIcon: 'WebApp',
      selected: selectedChip === 'Web',
      onClick: () => handleChipClick('Web'),
    },
    {
      label: 'Mobile',
      chipIcon: 'MobileDevice',
      selected: selectedChip === 'Mobile',
      onClick: () => handleChipClick('Mobile'),
    },
  ];

  const handleChipClick = (label) => {
    setSelectedChip((prev) => (prev === label ? 'All' : label)); // Toggle logic
  };

  const { accounts, instance } = useMsal();
  const dispatch = useDispatch();
  const { applicationsPageCMSData } = useSelector(
    (state) => state.applicationsPageReducer
  );

  useEffect(() => {
    const getTokenAndFetchData = async () => {
      try {
        const token = await getSilentToken(accounts[0], instance, [
          msalConstants.apiApplicationConfig.scopes[0],
        ]);

        if (token) {
          dispatch(fetchApplicationsPageData(token));
          dispatch(fetchCMSApplicationsPageData(token));
        } else {
          console.log('Token not retrieved');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    // Call the function
    getTokenAndFetchData();
  }, [accounts, instance]);

  return (
    <div className={styles.layout}>
      <div className={styles.applications_main}>
        <div className={styles.titlebar}>
          <div className={styles.title}>
            {applicationsPageCMSData[0]?.title}
          </div>
          <ButtonGroup size="md" selected={style}>
            <IconButton iconName="Grid" onClick={() => setStyle('Grid')} />
            <IconButton iconName="List" onClick={() => setStyle('List')} />
          </ButtonGroup>
        </div>
        <div className={styles.desc}>
          {applicationsPageCMSData[0]?.preamble}
        </div>
        <ChipFilter chipsData={chipsData} selected={selectedChip} />
      </div>

      <div>
        <div className={styles.section}>
          <div className={styles.heading}>
            {
              applicationsPageCMSData[0]?.contentArea[0].contentLink.expanded
                .name
            }
          </div>
          <CtaIcon
            icon="ChevronRight"
            onClick={function noRefCheck() {}}
            text="View All Apps"
            variant="blue"
          />
        </div>
      </div>

      <div>
        <div className={styles.section}>
          <div className={styles.heading}>
            {
              applicationsPageCMSData[0]?.contentArea[1].contentLink.expanded
                .name
            }
          </div>
          <CtaIcon
            icon="ChevronRight"
            onClick={function noRefCheck() {}}
            text="View All Apps"
            variant="blue"
          />
        </div>
      </div>

      <div>
        <div className={styles.section}>
          <div className={styles.heading}>
            {
              applicationsPageCMSData[0]?.contentArea[2].contentLink.expanded
                .name
            }
          </div>
          <CtaIcon
            icon="ChevronRight"
            onClick={function noRefCheck() {}}
            text="View All Apps"
            variant="blue"
          />
        </div>
      </div>
    </div>
  );
}

export default Applications;
