const languageMsg = {
  RECENTAPP_EMPTY:
    'You currently don’t have access to any apps. Visit the applications page to discover Weir Applications.',
  VIEW_WEIR_ID: 'View Weir iD',
  VIEW_ALL_APPS: 'View All Apps',
  VIEW_ALL_NEWS: 'View All News',
  LATEST_NEWS: 'Latest News',
  MESSAGE: 'message',
  SUBMIT_NOTIFICATION_TITLE: 'Request submitted',
  SUBMIT_NOTIFICATION_MESSAGE:
    'Your request will be reviewed by the App Manager and you will be notified via email if your request has been approved. Additional information might be requested via email by the App Manager before approval.',
};
export default languageMsg;
