/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import styles from './Home.module.css';
import {
  CtaIcon,
  WeirIDTile,
  AppTile,
  CardListBlock,
  Typography,
  Grid,
} from '@weirgroup/weir-dls';
import { msalConstants } from '../../services/msal.constants';
import { getSilentToken } from '../../services/TokenUtils';
import { useMsal } from '@azure/msal-react';
import { applicationRouteConstants } from '../../shared/_constants';
import {
  fetchCMSHomePageData,
  fetchHomePageData,
  fetchCMSLatestNewData,
  fetchOtherWeirAppsData,
  fetchCMSMoreAppsData,
  submitRequestMoreApps,
} from './HomePageAction';
import { useDispatch, useSelector } from 'react-redux';
import languageMsg from './languageMsg';
import { loaderActions } from '../../shared/_actions';

function Home() {
  const [greeting, setGreeting] = useState();
  const [userName, setUserName] = useState();
  const [profileName, setProfileName] = useState();
  const [userCompName, setUserCompName] = useState();
  const [userDesignation, setUserDesignation] = useState();
  const [userRecentApps, setUserRecentApps] = useState([]);
  const [userRecentNews, setUserRecentNews] = useState([]);
  const [userRecentNewsFilteredData, setUserRecentNewsFilteredData] = useState(
    []
  );
  const { accounts, instance } = useMsal();
  const [displayedApps, setDisplayedApps] = useState([]);
  const {
    homePageData,
    homePageCMSData,
    homePageNewsData,
    otherApps,
    otherAppsCMSData,
  } = useSelector((state) => state.homePageReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    const myDate = new Date();
    const currentHour = myDate.getHours();
    if (currentHour < 12) {
      setGreeting('Good Morning');
    } else if (currentHour >= 12 && currentHour <= 16) {
      setGreeting('Good Afternoon');
    } else if (currentHour > 16 && currentHour <= 24) {
      setGreeting('Good Evening');
    }
  }, []);

  useEffect(() => {
    const getTokenAndFetchData = async () => {
      try {
        const token = await getSilentToken(accounts[0], instance, [
          msalConstants.apiApplicationConfig.scopes[0],
        ]);

        if (token) {
          dispatch(fetchHomePageData(token));
          dispatch(fetchCMSHomePageData(token));
          dispatch(fetchCMSLatestNewData(token));
          dispatch(fetchOtherWeirAppsData(token));
          dispatch(fetchCMSMoreAppsData(token));
        } else {
          console.log('Token not retrieved');
        }
      } catch (error) {
        console.error('Error getting token:', error);
      }
    };

    // Call the function
    getTokenAndFetchData();
  }, [accounts, instance]);

  const getLastThreeRecentObjects = (data, listNumber) => {
    return data
      .filter((item) => item.lastAccessedOn !== null)
      .sort((a, b) => new Date(b.lastAccessedOn) - new Date(a.lastAccessedOn))
      .slice(0, listNumber);
  };

  const getLastThreeNewsObjects = (data, listNumber) => {
    if (data?.length > 0) {
      return data
        .filter((item) => item?.topics)
        .sort((a, b) => new Date(b.changed) - new Date(a.changed))
        .slice(0, listNumber);
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (homePageData?.length > 0) {
      setUserName(homePageData[0]?.firstName);
      let name = homePageData[0]?.firstName;
      let splitName = name.split(' ');
      let profileNameVal = splitName[0]?.charAt(0) + splitName[1]?.charAt(0);
      setProfileName(profileNameVal);
      setUserCompName(homePageData[0]?.company);
      setUserDesignation(homePageData[0]?.jobTitle);
      setUserRecentApps(getLastThreeRecentObjects(homePageData, 6));
    }

    if (homePageNewsData?.length > 0) {
      setUserRecentNews(getLastThreeNewsObjects(homePageNewsData, 3));
    }
  }, [homePageData, homePageCMSData]);

  useEffect(() => {
    if (homePageNewsData?.length > 0) {
      setUserRecentNews(getLastThreeNewsObjects(homePageNewsData, 3));
    }
  }, [homePageNewsData]);

  const onWeirIDTileClick = (e) => {
    // alert('View Weir ID Clicked');
  };

  const onViewAllAppsClick = (e) => {
    window.location.assign(applicationRouteConstants.APPLICATIONS);
  };

  const onRecentAppsClick = (e) => {
    // alert('onRecentAppsClick Clicked');
  };

  const onViewAllNewsClick = (e) => {
    const newWindow = window.open(
      'https://www.global.weir/newsroom/global-news/',
      '_blank',
      'noopener'
    );

    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const onNewsCardClick = (e) => {
    window.open(e?.contentLink?.url, '_blank');
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setUserRecentApps(getLastThreeRecentObjects(homePageData, 3));
    } else {
      setUserRecentApps(getLastThreeRecentObjects(homePageData, 6));
    }

    if (window.innerWidth <= 1300 && window.innerWidth >= 800) {
      setUserRecentNews(getLastThreeNewsObjects(homePageNewsData, 2));
    } else if (window.innerWidth <= 800) {
      setUserRecentNews(getLastThreeNewsObjects(homePageNewsData, 1));
    } else {
      setUserRecentNews(getLastThreeNewsObjects(homePageNewsData, 3));
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize, false);
  }, [homePageData, homePageCMSData, homePageNewsData]);

  useEffect(() => {
    let data = getLastThreeNewsObjects(homePageNewsData, 3);
    let filteredData = [];
    for (let i = 0; i < data.length; i++) {
      filteredData.push({
        imgSrc: data[i]?.listingImage.url,
        title: data[i]?.listingTitle,
        description: data[i]?.listingIntro,
        chips: data[i]?.contentType,
        date: new Date([data[i]?.changed]),
        onClick: (e) => onNewsCardClick(data[i]),
        pageTypeTag: [data[i]?.resourceType],
        pageTypeTagIcon: 'DXP4GSignal',
      });
    }
    setUserRecentNewsFilteredData(filteredData);
  }, [homePageNewsData]);

  const getTokenAndSubmitMoreAppsData = async (selectedAppId) => {
    dispatch(loaderActions.show());
    try {
      const token = await getSilentToken(accounts[0], instance, [
        msalConstants.apiApplicationConfig.scopes[0],
      ]);

      if (token) {
        dispatch(loaderActions.hide());
        dispatch(submitRequestMoreApps(token, [selectedAppId]));
      } else {
        dispatch(loaderActions.hide());
        console.log('Token not retrieved');
      }
    } catch (error) {
      dispatch(loaderActions.hide());
      // Handle any errors that occur during token generation
      console.error('Error getting token:', error);
    }
  };

  useEffect(() => {
    const appRequest = otherApps.filter((app) => app.isRequested === false);
    const sortAppName = appRequest.sort((a, b) =>
      a.applicationName.localeCompare(b.applicationName)
    );
    const mergedData = sortAppName.map((app) => {
      const cmsData = otherAppsCMSData.find(
        (cms) => cms.clientId === app.applicationObjectId
      );

      const appImgUrl = cmsData?.image?.url;
      const imgUrl = cmsData?.appLogo?.url;

      console.log('appImgUrl:', appImgUrl);
      console.log('imgUrl:', imgUrl);

      return {
        ...app,
        appImgUrl,
        imgUrl,
      };
    });

    setDisplayedApps(mergedData);
  }, [otherApps, otherAppsCMSData]);
  const displayedAppsInHomePage = displayedApps.slice(0, 3);

  return (
    <div className={styles.layout}>
      <div className={styles.greeting}>
        <Typography variant="H1" color="black">
          {greeting} {homePageData[0]?.firstName}
        </Typography>
      </div>
      <div className={styles.desc}>
        <Grid desktopcolumns={8} tabletcolumns={8} mobilecolumns={4}>
          <Typography variant="P1" color="black">
            {homePageCMSData[0]?.preamble}
          </Typography>
        </Grid>
      </div>

      <div>
        <div className={styles.profileWrapper}>
          {homePageData && homePageData?.length > 0 && (
            <div className={styles.weirIDTileWrapper}>
              <WeirIDTile
                onClick={onWeirIDTileClick}
                disabled={false}
                variant="white"
                profileAvatar={profileName}
                profileTitle={userName}
                // ProfileTitleIcon="WeirId"
                profileCompany={userCompName}
                profileDesignation={userDesignation}
              ></WeirIDTile>
            </div>
          )}
          <div className={styles.recentApps}>
            <div className={styles.recentAppsViewAllApps}>
              <div className={styles.heading}>
                <Typography variant="H3" color="black">
                  {homePageCMSData[0]?.contentArea[0].contentLink.expanded.name}
                </Typography>
              </div>
              <div>
                <CtaIcon
                  icon="ChevronRight"
                  onClick={onViewAllAppsClick}
                  text={languageMsg.VIEW_ALL_APPS}
                  variant="blue"
                />
              </div>
            </div>
            {userRecentApps?.length === 0 && (
              <div className={styles.recentAppEmpty}>
                <Typography variant="P1" color="black">
                  {languageMsg.RECENTAPP_EMPTY}
                </Typography>
              </div>
            )}
            <div className={styles.recentAppsViewAllCard}>
              {userRecentApps &&
                userRecentApps?.length > 0 &&
                userRecentApps.map((app) => {
                  if (app?.lastAccessedOn) {
                    return (
                      <AppTile
                        style="Grid"
                        type="App"
                        darkMode={false}
                        title={app?.displayName}
                        tags={app?.isMobileApp ? ['native'] : ['web']}
                        weirIDEnabled={false}
                        status=""
                        applyModalInternalGrid={false}
                        modalType="large"
                        requested={false}
                        onClick={onRecentAppsClick}
                        appImgUrl={app?.logoUrl}
                      ></AppTile>
                    );
                  }
                  return null;
                })}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.newsWrapper}>
        <CardListBlock
          cardType="Article"
          cardLayout="Stacked"
          showArticleCardImage={true}
          cardsheading={languageMsg.LATEST_NEWS}
          ctaText={languageMsg.VIEW_ALL_NEWS}
          cardLayoutStyle={'Classic'}
          cards={userRecentNewsFilteredData}
          ctaOnclick={onViewAllNewsClick}
        ></CardListBlock>
      </div>

      {otherApps.length > 0 && (
        <div>
          <div className={styles.appsWrapper}>
            <div className={styles.heading}>
              <Typography variant="H3" color="black">
                {homePageCMSData[0]?.contentArea[2].contentLink.expanded.name}
              </Typography>
            </div>
            <CtaIcon
              icon="ChevronRight"
              onClick={onViewAllAppsClick}
              text={languageMsg.VIEW_ALL_APPS}
              variant="blue"
            />
          </div>
          <div className={styles.appTile}>
            {displayedAppsInHomePage.map((app) => (
              <AppTile
                modalType="large"
                onRequestAccessClick={() =>
                  getTokenAndSubmitMoreAppsData(app.applicationObjectId)
                }
                status="Discover"
                tags={app?.appType === 'Mobile' ? ['native'] : ['web']}
                title={app.applicationName}
                type="Featured"
                // weirIDEnabled
                appImgUrl={app?.appImgUrl}
                imgUrl={app?.imgUrl}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
